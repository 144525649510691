import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Footer = ({ siteTitle, logo }) => (
  <FooterDefault>
    <div className="footer__content">
      <div className="logo__wrapper">
        <Link to="/">
          <img src={logo} alt={siteTitle} />
        </Link>
      </div>
      <div className="copyright">
        <p>
          Copyright © {new Date().getFullYear()} safetylogz ltd. All Rights
          Reserved
        </p>
      </div>
    </div>
  </FooterDefault>
)

const FooterDefault = styled.footer`
  background: #eff4f5;
  border-top: 1px solid #2f4f5d;
  position: fixed;
  bottom: 0;
  height: 110px;
  left: 0;
  right: 0;
  color: #2f4f5d;
  width: 100%;
  @media only screen and (max-width: 414px) {
    height: 90px;
  }
  .footer__content {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .logo__wrapper {
    img {
      width: 140px;
      margin-bottom: 0;
      @media only screen and (max-width: 414px) {
        width: 110px;
      }
    }
  }
  .copyright {
    p {
      font-size: 14px;
      float: right;
      margin-bottom: 0;
      @media only screen and (max-width: 414px) {
        font-size: 12px;
      }
    }
  }
`

export default Footer
