import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

const Header = ({ siteTitle, logo }) => (
  <HeaderDefault>
    <div className="navbar" style={{}}>
      <div>
        <Link to="/">
          <h1 style={{ margin: 0 }}>
            <Logo src={logo} alt={`${siteTitle}`} />
          </h1>
        </Link>
      </div>
      <Nav>
        <Link to="/blog">Blog</Link>
      </Nav>
    </div>
  </HeaderDefault>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const HeaderDefault = styled.header`
  margin-bottom: 1.45rem;
  z-index: 10000;
  position: relative;
  border-bottom: 1px solid #2f4f5d;
  .navbar {
    margin: 0 auto;
    max-width: 1200px;
    padding: 1.45rem 1.0875rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    h1 {
      line-height: 0;
    }
  }
`

const Logo = styled.img`
  width: 190px;
  margin: auto;
  @media only screen and (max-width: 414px) {
    width: 150px;
  }
`

const Nav = styled.nav`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: -15px;

  a {
    text-decoration: none;
    color: #2f4f5d;
    padding: 15px;
    font-size: 18px;
    float: right;
    font-weight: bold;
  }
  a:hover,
  a:visited {
    text-decoration: underline;
  }
`

export default Header
